import { useLocation, useNavigate, useParams } from "react-router-dom";

import ReportCardTable from "./components/ReportCardTable";
import ReportCardOverview from "./components/ReportCardOverview";
import SelectInput from "../../../../shared/components/form/SelectInput";
import useFetchClassDetails from "../../../../shared/api/hooks/useFetchClassDetails";
import useFetchClassReportCard from "../../../../shared/api/cards/useFetchClassReportCard";
import useFetchAcademicSessions from "../../../../shared/api/archives/useFetchAcademicSessions";
import useFetchClassSubjects from "../../../../shared/api/class/subjects/useFetchClassSubjects";

const ReportCards = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const session = query.get("session");
  const term = query.get("term");

  const navigate = useNavigate();
  const { classId, schoolId, teacherId } = useParams();

  const { data: academicSessions } = useFetchAcademicSessions();
  const { loading: classLoading, data: classDetails } =
    useFetchClassDetails(classId);
  const { loading: subjectLoading, data: subjects } =
    useFetchClassSubjects(classId);

  const { data: reportCards, loading: reportCardsLoading } =
    useFetchClassReportCard(session, term);

  function handleSelect(params) {
    navigate(
      `/${teacherId}/workspace/${schoolId}/class/${classId}/student/${params?.studentId}/reportcard?session=${session}&term=${term}`
    );
  }

  function handleSelectTerm(_term) {
    navigate(
      `/${teacherId}/workspace/${schoolId}/class/${classId}/reportcard?session=${session}&term=${_term}`
    );
  }

  function handleSelectSession(_session) {
    navigate(
      `/${teacherId}/workspace/${schoolId}/class/${classId}/reportcard?session=${_session}&term=${term}`
    );
  }

  return (
    <div>
      <div className="full_page">
        <div className="mb-5 flex items-center justify-between">
          <div className="page_title_container">
            <h1 className="page_title">Report Cards</h1>

            <p className="page_subtitle">
              {classDetails
                ? `(${classDetails?.category} ${classDetails?.suffix})`
                : null}
            </p>
          </div>
        </div>

        {/* <ClassNavigationBar activeIndex={5} /> */}
        <div className="flex items-center gap-2 mb-2 justify-end">
          <SelectInput
            placeholder={"Session"}
            options={academicSessions}
            containerStyle={"border-0"}
            className="min-w-[110px] mb-0 bg-white ssm:min-w-[150px]"
            handleSelect={(e) => handleSelectSession(e.currentTarget.value)}
            optionKeyLabel="session"
            optionValueLabel="session"
            value={session || ""}
          />

          <SelectInput
            value={term || ""}
            placeholder={"Term"}
            containerStyle={"border-0"}
            className="min-w-[110px] mb-0 bg-white ssm:min-w-[150px]"
            options={[
              { name: "First Term", value: "first" },
              { name: "Second Term", value: "second" },
              { name: "Third Term", value: "third" },
            ]}
            handleSelect={(e) => handleSelectTerm(e.currentTarget.value)}
          />
        </div>

        <div className="main_content">
          <ReportCardOverview
            classDetails={classDetails}
            classLoading={classLoading}
            subjectLength={subjects?.length}
            reportCardsLength={reportCards?.length}
          />

          <ReportCardTable
            data={reportCards}
            loading={reportCardsLoading}
            subjectLoading={subjectLoading}
            handleSelect={handleSelect}
          />
        </div>
      </div>
    </div>
  );
};
export default ReportCards;
