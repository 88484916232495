import { useState } from "react";
import { useDispatch } from "react-redux";
import { AiOutlineEye } from "react-icons/ai";

import { setUserDetails } from "../../../redux/slices/auth/slice";

import authImage from "../../../assets/images/signup.png";
import FormButton from "../../../shared/components/form/FormButton";
import FormInput from "../../../shared/components/form/FormInput";
import Logo from "../../../shared/components/Logo";
import PageLoader from "../../../shared/components/PageLoader";
import useFetchInvitation from "../../../shared/api/auth/invitation/useFetchInvitation";
import useAcceptSchoolInvite from "../../../shared/api/auth/invitation/useAcceptSchoolInvite";

const AcceptInvite = () => {
  const dispatch = useDispatch();

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [password, setPassword] = useState("");

  const { data: invite, loading: loadingInvite } = useFetchInvitation();
  const { request, loading } = useAcceptSchoolInvite();

  async function acceptInvite(e) {
    e.preventDefault();
    dispatch(setUserDetails(null));

    await request({
      password,
      email: invite?.email,
      schoolId: invite?.schoolId,
    });
  }

  if (loadingInvite) {
    return <PageLoader loading={loadingInvite} />;
  }

  return (
    <div className="auth_page">
      <div className="auth_page_content_grid">
        <div className="auth_logo_wrapper">
          <Logo />
        </div>

        <div className="auth_page_content">
          {invite?.schoolId ? (
            <>
              <h2 className="auth_page_title">Welcome to Get My Figures!</h2>
              <h2 className="auth_page_title text-left">
                Invitation from{" "}
                <span className="capitalize text-primary">
                  {invite?.schoolName}
                </span>
              </h2>
              <p className="mb-1 text-yankee_blue text-base">
                You have been invited to collaborate on GMF with{" "}
                <span className="text-primary font-semibold capitalize">
                  {invite?.schoolName}
                </span>
              </p>
              <p className="mb-10 text-yankee_blue text-base">
                Create a strong password to continue
              </p>

              <form onSubmit={acceptInvite}>
                <FormInput
                  label={"Password"}
                  type={passwordVisible ? "text" : "password"}
                  placeholder={"Enter your password"}
                  required
                  rightIcon={
                    <AiOutlineEye className="w-3 h-3 cursor-pointer" />
                  }
                  onClickRightIcon={() => setPasswordVisible(!passwordVisible)}
                  onChange={(e) => setPassword(e.currentTarget.value)}
                />

                <FormButton
                  title={"Accept Invitation"}
                  type="submit"
                  loading={loading}
                  className="mt-[10%]"
                />
              </form>
            </>
          ) : (
            <div className="grid place-content-center h-full mt-[40%]">
              <p>Invite record not found</p>
            </div>
          )}
        </div>
      </div>

      <div className="auth_page_image_grid">
        <div className="auth_image_container">
          <img src={authImage} alt="GMF" />
        </div>
      </div>
    </div>
  );
};

export default AcceptInvite;
