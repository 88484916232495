import { useEffect } from "react";
import { useParams } from "react-router-dom";
import useFetchStudentInfo from "../../../../shared/api/class/students/useFetchStudentInfo";
import useFetchStudentClassHistory from "../../../../shared/api/class/students/useFetchStudentClassHistory";
import PageLoader from "../../../../shared/components/PageLoader";
import BasicDetails from "../../../../shared/components/students/BasicDetails";
import ClassHistories from "../../../../shared/components/students/ClassHistories";
import MedicalDetails from "../../../../shared/components/students/MedicalDetails";
import GuardianDetails from "../../../../shared/components/students/GuardianDetails";

const StudentDetails = () => {
  const { studentId } = useParams();

  const { data: guardian } = useFetchStudentInfo(studentId, "guardian");
  const { data: studentDetails, studentLoading } = useFetchStudentInfo(
    studentId,
    "basic"
  );

  const { data: classHistories, request: fetchClassHistory } =
    useFetchStudentClassHistory();

  useEffect(() => {
    fetchClassHistory({
      studentId,
    });
  }, [fetchClassHistory, studentId]);

  if (studentLoading) {
    return <PageLoader loading={studentLoading} />;
  }

  return (
    <div className={`full_page`}>
      <div className="mb-5 flex items-center justify-between">
        <div className="page_title_container">
          <h1 className="page_title">
            {studentDetails?.lastName} {studentDetails?.firstName} (
            {studentDetails?.category} {studentDetails?.suffix})
          </h1>
        </div>
      </div>

      {/* <EditStudentNavigator type={type} /> */}

      <div className="mb-10 slg:flex slg:items-start slg:justify-between">
        <div className="mb-8 slg:w-[59%] slg:mb-0">
          <BasicDetails
            details={studentDetails}
            category={studentDetails?.category}
            suffix={studentDetails?.suffix}
          />
        </div>

        <div className="slg:w-[40%]">
          <ClassHistories classHistories={classHistories} />
        </div>
      </div>

      <div className="grid gap-8 slg:grid-cols-2 slg:gap-4">
        <MedicalDetails details={studentDetails} />

        <GuardianDetails guardian={guardian} />
      </div>
    </div>
  );
};

export default StudentDetails;
