import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectSchoolDetails } from "../../../redux/slices/school/selectors";
import client from "../client";
import { setSchoolData } from "../../../redux/slices/school/slice";
import handleApiError from "../../hooks/handleApiError";

export default function useFetchSchool() {
  const dispatch = useDispatch();
  const { schoolId } = useParams();

  const school = useSelector(selectSchoolDetails);
  const [loading, setLoading] = useState(false);

  const request = useCallback(
    async (id) => {
      try {
        setLoading(true);
        const res = await client.get(`/school/${id}`);

        if (res.data?.success) {
          dispatch(setSchoolData(res.data?.data));
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleApiError(error);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (schoolId && !school) request(schoolId);
  }, [request, schoolId, school]);

  return { request, loading };
}
