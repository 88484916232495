import { useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";

import Logo from "../shared/components/Logo";
import Sidebar from "../shared/components/Sidebar";
import useFetchSchool from "../shared/api/school/useFetchSchool";
import useFetchUserDetails from "../shared/api/user/useFetchUserDetails";
import useFetchActiveCalendar from "../shared/api/calendar/useFetchActiveCalendar";
import useFetchNotificationsCount from "../shared/api/notifications/useFetchNotificationsCount";

const Container = () => {
  const { teacherId } = useParams();

  useFetchSchool();
  useFetchUserDetails();
  useFetchActiveCalendar();
  useFetchNotificationsCount(teacherId);

  const [open, setOpen] = useState(false);

  const toggleMenu = () => {
    setOpen(!open);
  };

  return (
    <div className="bg-ghost_white min-h-screen">
      <div className="w-fit border-0 fixed top-0 bottom-0 left-0 hidden lg:block bg-white">
        <Sidebar />
      </div>

      <div className="flex items-center justify-between px-5 bg-white lg:hidden">
        <div className="py-5">
          <Logo />
        </div>

        <div className="cursor-pointer">
          <AiOutlineMenu color="#000000" size={30} onClick={toggleMenu} />
        </div>
      </div>

      <div className={`relative lg:ml-[230px] min-h-screen max-w-[1600px]`}>
        <Outlet />
      </div>

      <div
        className={`z-10 fixed top-0 left-0 bottom-0 right-0 lg:hidden ${
          open ? "visible" : "hidden"
        }`}
      >
        <div className="bg-white h-full w-fit">
          <Sidebar toggleMenu={toggleMenu} />
        </div>

        <div
          className="bg-black/50 w-full h-full fixed top-0 left-[230px] bottom-0 right-0"
          onClick={toggleMenu}
        />
      </div>

      {/* <div
        className={`relative ${
          windowWidth < 1300
            ? "ml-0 min-[800px]:ml-[100px]"
            : "min-[800px]:ml-[270px]"
        } min-h-screen`}
      >
        <Outlet />
      </div> */}
    </div>
  );
};
export default Container;
