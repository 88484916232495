import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";

import { setUserDetails } from "../../../redux/slices/auth/slice";

import PageLoader from "../../../shared/components/PageLoader";
import FormButton from "../../../shared/components/form/FormButton";
import FormInput from "../../../shared/components/form/FormInput";
import SelectInput from "../../../shared/components/form/SelectInput";
import authImage from "../../../assets/images/signup.png";
import Logo from "../../../shared/components/Logo";
import useFetchInvitation from "../../../shared/api/auth/invitation/useFetchInvitation";
import useRegisterTeacherBySchoolInvitation from "../../../shared/api/auth/invitation/useRegisterTeacherBySchoolInvitation";

const InviteRegistration = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [passwordVisible, setPasswordVisible] = useState(false);

  const [account, setAccount] = useState(null);
  const { data: invite, loading: loadingInvite } = useFetchInvitation();
  const { request, loading } = useRegisterTeacherBySchoolInvitation();

  async function register(e) {
    e.preventDefault();
    dispatch(setUserDetails(null));

    await request({
      ...account,
      schoolId: invite?.schoolId,
      email: invite?.email,
    });
  }

  if (loadingInvite) {
    return <PageLoader loading={loadingInvite} />;
  }

  return (
    <div className="auth_page">
      <div className="auth_page_content_grid">
        <div className="auth_logo_wrapper">
          <Logo />
        </div>

        <div className="auth_page_content">
          <h2 className="auth_page_title">Welcome to Get My Figures!</h2>
          <p className="mb-10 text-yankee_blue text-base">
            Please create your account to accept the invitation from{" "}
            <span className="text-primary font-semibold capitalize">
              {invite?.schoolName}
            </span>
          </p>

          <form onSubmit={register}>
            <div className="grid grid-cols-2 gap-5">
              <FormInput
                label={"Last Name"}
                placeholder={"Enter last name"}
                required
                minLength={3}
                value={account?.lastName || ""}
                onChange={(e) =>
                  setAccount({ ...account, lastName: e.currentTarget.value })
                }
              />

              <FormInput
                label={"First Name"}
                placeholder={"Enter first name"}
                required
                minLength={3}
                value={account?.firstName || ""}
                onChange={(e) =>
                  setAccount({ ...account, firstName: e.currentTarget.value })
                }
              />
            </div>

            <div className="grid grid-cols-2 gap-5">
              <FormInput
                label={"Phone Number"}
                placeholder={"Enter phone number"}
                required
                minLength={11}
                maxLength={11}
                value={account?.phone || ""}
                onChange={(e) =>
                  setAccount({ ...account, phone: e.currentTarget.value })
                }
              />

              <SelectInput
                label={"Title"}
                placeholder={"Select title"}
                options={[
                  { name: "Mr", value: "Mr" },
                  { name: "Mrs", value: "Mrs" },
                  { name: "Miss", value: "Miss" },
                ]}
                required
                handleSelect={(e) =>
                  setAccount({ ...account, title: e.currentTarget.value })
                }
              />
            </div>

            <FormInput
              label={"Email Address"}
              placeholder={"Enter email address"}
              required
              minLength={6}
              type="email"
              value={invite?.email || ""}
              disabled={true}
            />

            <FormInput
              label={"Password"}
              placeholder={"Enter a strong password"}
              type={passwordVisible ? "text" : "password"}
              required
              minLength={6}
              value={account?.password || ""}
              rightIcon={<AiOutlineEye className="w-3 h-3 cursor-pointer" />}
              onClickRightIcon={() => setPasswordVisible(!passwordVisible)}
              onChange={(e) =>
                setAccount({
                  ...account,
                  password: e.currentTarget.value,
                })
              }
            />

            <FormButton
              title={"Create account"}
              className="mt-[5%]"
              type="submit"
              loading={loading}
            />

            <p
              className="text-yankee_blue text-xs mt-2 cursor-pointer"
              onClick={() => navigate("/login")}
            >
              Already have an account?{" "}
              <span className="text-primary">Sign In</span>
            </p>
          </form>
        </div>
      </div>

      <div className="auth_page_image_grid">
        <div className="auth_image_container">
          <img src={authImage} alt="GMF" />
        </div>
      </div>
    </div>
  );
};
export default InviteRegistration;
