import { useCallback, useState } from "react";
import handleApiError from "../../../hooks/handleApiError";
import client from "../../client";

export default function useFetchStudentClassHistory() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const request = useCallback(async ({ studentId, classId, session }) => {
    try {
      setLoading(true);

      const res = await client.get("/archives/class-history", {
        params: {
          studentId,
          classId,
          session,
        },
      });

      setData(res.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, []);

  return { data, loading, request };
}
