import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";

import Logo from "../../../shared/components/Logo";
import FormInput from "../../../shared/components/form/FormInput";
import FormButton from "../../../shared/components/form/FormButton";
import authImage from "../../../assets/images/login.png";
import useLogin from "../../../shared/api/auth/useLogin";

const Login = () => {
  const navigate = useNavigate();

  const [passwordVisible, setPasswordVisible] = useState(false);

  const [credential, setCredential] = useState({ accountType: "teacher" });
  const { request: loginRequest, loading } = useLogin();

  async function login(e) {
    e.preventDefault();
    await loginRequest(credential);
  }

  return (
    <div className="auth_page">
      <div className="auth_page_content_grid">
        <div className="auth_logo_wrapper">
          <Logo />
        </div>

        <div className="auth_page_content">
          <h2 className={"auth_page_title"}>Hello, Welcome Back!</h2>

          {/* {errorMessage ? (
            <ErrorMessage message={"Error from sending api"} />
          ) : null} */}

          <form className="mt-5" onSubmit={login}>
            <FormInput
              label={"Email Address"}
              placeholder={"Enter email address"}
              required
              value={credential?.email || ""}
              onChange={(e) =>
                setCredential({
                  ...credential,
                  email: e.currentTarget.value,
                })
              }
            />

            <FormInput
              label={"Password"}
              type={passwordVisible ? "text" : "password"}
              className="mb-3"
              placeholder={"Enter password"}
              rightIcon={<AiOutlineEye className="w-3 h-3 cursor-pointer" />}
              onClickRightIcon={() => setPasswordVisible(!passwordVisible)}
              required
              value={credential?.password || ""}
              onChange={(e) =>
                setCredential({
                  ...credential,
                  password: e.currentTarget.value,
                })
              }
            />

            <p
              className="text-primary text-xs text-right cursor-pointer"
              onClick={() => navigate("/forgot-password")}
            >
              Forgot Password?
            </p>

            <FormButton
              title={"Login"}
              className="mt-[20%]"
              loading={loading}
              type={"submit"}
            />

            <p
              className="text-yankee_blue text-xs mt-2 cursor-pointer"
              onClick={() => navigate("/register")}
            >
              You don't have an account yet?{" "}
              <span className="text-primary">Sign up</span>
            </p>
          </form>
        </div>
      </div>

      <div className="auth_page_image_grid">
        <div className="auth_image_container">
          <img
            src={authImage}
            alt="GMF"
            className="block w-full h-full object-cover"
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
