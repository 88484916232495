import { useCallback, useEffect, useState } from "react";
import client from "../client";
import { useParams } from "react-router-dom";
import handleApiError from "../../hooks/handleApiError";

export default function useFetchActiveClassRoles() {
  const { teacherId } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const request = useCallback(async () => {
    try {
      setLoading(true);

      const res = await client.get(
        `/job/teacher/${teacherId}/class-role/active`
      );

      setData(res.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, [teacherId]);

  useEffect(() => {
    request();
  }, [request]);

  return { data, loading, request };
}
