import { useState } from "react";
import { useParams } from "react-router-dom";

import StudentTable from "./components/StudentTable";
import StudentsOverview from "./components/StudentsOverview";
import Pagination from "../../../../shared/components/Pagination";
import useFetchClassDetails from "../../../../shared/api/hooks/useFetchClassDetails";
import useFetchClassStudents from "../../../../shared/api/class/students/useFetchClassStudents";

const Students = () => {
  const { classId } = useParams();

  const [page, setPage] = useState(1);

  const { loading: classLoading, data: classDetails } =
    useFetchClassDetails(classId);
  const {
    loading: studentsLoading,
    data: students,
    pagination,
  } = useFetchClassStudents(classId, page);

  return (
    <div className={"full_page"}>
      <div className="mb-5 flex items-center justify-between">
        <div className="page_title_container">
          <h1 className="page_title">Students</h1>

          <p className="page_subtitle">
            {classDetails
              ? `(${classDetails?.category} ${classDetails?.suffix})`
              : null}
          </p>
        </div>
      </div>

      <div className="main_content">
        <StudentsOverview
          classDetails={classDetails}
          classLoading={classLoading}
        />

        <StudentTable
          students={students}
          studentsLoading={studentsLoading}
          // studentId={studentId}
        />

        <Pagination setPage={setPage} pagination={pagination} />
      </div>
    </div>
  );
};
export default Students;
