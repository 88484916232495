import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  getAverage,
  getScoreObtained,
  handleToast,
} from "../../../../shared/utils/functions";
import { selectSchoolDetails } from "../../../../redux/slices/school/selectors";

import ModifyRemark from "./components/ModifyRemark";
import NewScorecard from "./components/NewScorecard";
import Loader from "../../../../shared/components/Loader";
import DeleteScorecard from "./components/DeleteScorecard";
import SideModal from "../../../../shared/components/SideModal";
import PageLoader from "../../../../shared/components/PageLoader";
import EmptyTable from "../../../../shared/components/EmptyTable";
import SelectInput from "../../../../shared/components/form/SelectInput";
import PageActions from "../../../../shared/components/general/PageActions";
import ScorecardsByTermTableBody from "./components/ScorecardsByTermTableBody";
import useFetchCalendars from "../../../../shared/api/calendar/useFetchCalendars";
import ScorecardsByTermTableHeader from "./components/ScorecardsByTermTableHeader";
import ScorecardsByTermTableOverview from "./components/ScorecardsByTermTableOverview";
import useFetchStudentBasicInfo from "../../../../shared/api/hooks/useFetchStudentBasicInfo";
import useFetchStudentReportCard from "../../../../shared/api/cards/useFetchStudentReportCard";
import useFetchAcademicSessions from "../../../../shared/api/archives/useFetchAcademicSessions";

const StudentReportCard = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const scorecardId = query.get("scorecardId");
  const action = query.get("action");
  const session = query.get("session");
  const term = query.get("term");

  const school = useSelector(selectSchoolDetails);

  const navigate = useNavigate();
  const { classId, schoolId, studentId, teacherId } = useParams();

  const { data: academicSessions } = useFetchAcademicSessions();
  const { loading: studentDetailsLoading, data: studentDetails } =
    useFetchStudentBasicInfo(studentId);

  const { data: calendars } = useFetchCalendars(school?.state);
  const [selectedCalendar, setSelectedCalendar] = useState(null);
  const {
    reportCard,
    scorecards,
    loading: reportCardLoading,
    request: fetchReportCard,
  } = useFetchStudentReportCard(selectedCalendar);

  // calculate each term data
  const obtainableScore = scorecards?.length * 100;
  const scoreObtained = getScoreObtained(scorecards);

  useEffect(() => {
    if (term && session && calendars?.length) {
      const found = calendars?.find(
        (el) => (el?.session === session) & (el?.term === term)
      );

      if (!found)
        return handleToast("No calendar for selected term", "warning");

      setSelectedCalendar(found);
    }
  }, [term, session, calendars]);

  function closeSideModal() {
    navigate(
      `/${teacherId}/workspace/${schoolId}/class/${classId}/student/${studentId}/reportcard?session=${session}&term=${term}`
    );
  }

  function handleDeleteScorecard(scorecard) {
    navigate(
      `/${teacherId}/workspace/${schoolId}/class/${classId}/student/${studentId}/reportcard?scorecardId=${scorecard?.scorecardId}&action=delete`
    );
  }

  function handleSelectTerm(_term) {
    navigate(
      `/${teacherId}/workspace/${schoolId}/class/${classId}/student/${studentId}/reportcard?session=${session}&term=${_term}`
    );
  }

  function handleSelectSession(_session) {
    navigate(
      `/${teacherId}/workspace/${schoolId}/class/${classId}/student/${studentId}/reportcard?session=${_session}&term=${term}`
    );
  }

  if (studentDetailsLoading)
    return <PageLoader loading={studentDetailsLoading} />;

  return (
    <div>
      <div className={action ? "page" : "full_page"}>
        <div className="mb-5 flex items-center justify-between">
          <div className="page_title_container">
            <h1 className="page_title">
              {`${studentDetails?.lastName || ""} ${
                studentDetails?.firstName || ""
              }'s`}{" "}
              Report Card
            </h1>
          </div>

          <PageActions
            actions={[
              {
                label: "Add Scorecard",
                onClick: () => {
                  navigate(
                    `/${teacherId}/workspace/${schoolId}/class/${classId}/student/${studentId}/reportcard?action=new-scorecard&session=${session}&term=${term}`
                  );
                },
              },
              {
                label: "Class Teacher Remark",
                onClick: () => {
                  navigate(
                    `/${teacherId}/workspace/${schoolId}/class/${classId}/student/${studentId}/reportcard?action=teacher-remark&session=${session}&term=${term}`
                  );
                },
              },
            ]}
          />
        </div>

        <div className="flex items-center gap-2 mb-2 justify-end">
          <SelectInput
            placeholder={"Session"}
            options={academicSessions}
            containerStyle={"border-0"}
            className="min-w-[110px] mb-0 bg-white ssm:min-w-[150px]"
            handleSelect={(e) => handleSelectSession(e.currentTarget.value)}
            optionKeyLabel="session"
            optionValueLabel="session"
            value={session || ""}
          />

          <SelectInput
            value={term || ""}
            placeholder={"Term"}
            containerStyle={"border-0"}
            className="min-w-[110px] mb-0 bg-white ssm:min-w-[150px]"
            options={[
              { name: "First Term", value: "first" },
              { name: "Second Term", value: "second" },
              { name: "Third Term", value: "third" },
            ]}
            handleSelect={(e) => handleSelectTerm(e.currentTarget.value)}
          />
        </div>

        <div className="main_content mb-10">
          <ScorecardsByTermTableOverview
            reportCard={reportCard}
            obtainedScore={scoreObtained}
            obtainableScore={obtainableScore}
            average={`${getAverage(scoreObtained, obtainableScore)}%`}
          />

          <div className="table_wrapper">
            <div className="min-w-[800px]">
              <ScorecardsByTermTableHeader />

              {reportCardLoading ? (
                <Loader loading={reportCardLoading} />
              ) : null}

              {!reportCardLoading && !scorecards?.length ? (
                <EmptyTable
                  // image={subjectBookImage}
                  message={"No scorecard records found"}
                />
              ) : null}

              <ScorecardsByTermTableBody
                data={scorecards}
                handleDeleteScorecard={handleDeleteScorecard}
                scorecardId={scorecardId}
              />
            </div>
          </div>
        </div>
      </div>

      <SideModal isVisible={action} closeSideModal={closeSideModal}>
        <DeleteScorecard
          action={action}
          scorecardId={scorecardId}
          handleCancelDeleteScorecard={closeSideModal}
          fetchReportCard={fetchReportCard}
        />

        <NewScorecard
          action={action}
          fetchReportCard={fetchReportCard}
          reportCard={reportCard}
          handleCancelNewScorecard={closeSideModal}
        />

        <ModifyRemark
          action={action}
          reportCard={reportCard}
          fetchReportCard={fetchReportCard}
          closeSideModal={closeSideModal}
        />
      </SideModal>

      {/* {action ? (
        <div className="page_dark_overlay" onClick={closeSideModal} />
      ) : null} */}

      {/* <div className={`page_right_bar ${action ? "block" : ""}`}>
        <DeleteScorecard
          action={action}
          scorecardId={scorecardId}
          handleCancelDeleteScorecard={closeSideModal}
          fetchReportCard={fetchReportCard}
        />

        <NewScorecard
          action={action}
          fetchReportCard={fetchReportCard}
          reportCard={reportCard}
          handleCancelNewScorecard={closeSideModal}
        />

        <ModifyRemark
          action={action}
          reportCard={reportCard}
          fetchReportCard={fetchReportCard}
          closeSideModal={closeSideModal}
        />
      </div> */}
    </div>
  );
};

export default StudentReportCard;
