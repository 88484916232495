import StudentDetailRow from "./StudentDetailRow";
import Loader from "../../../../../shared/components/Loader";
import IMAGES from "../../../../../shared/constant/images";
import useFetchStudentInfo from "../../../../../shared/api/class/students/useFetchStudentInfo";

const StudentDetails = ({ studentId, closeSideModal }) => {
  const { data: details, loading } = useFetchStudentInfo(studentId, "basic");
  const { data: guardian } = useFetchStudentInfo(studentId, "guardian");

  if (!studentId) return null;

  if (loading) return <Loader loading={loading} />;

  return (
    <div className="h-full flex flex-col">
      <div className="w-full">
        <img
          src={IMAGES.studentAvatar}
          alt=""
          className="w-full object-cover"
        />
      </div>

      <div className="flex-1 flex flex-col">
        <div className="py-3 px-3">
          <h3 className="font-bold text-yankee_blue mb-3 text-lg capitalize">
            {details?.lastName || ""} {details?.firstName || ""}
          </h3>

          <StudentDetailRow
            label={"Email address"}
            value={details?.email || "N/A"}
          />
          <StudentDetailRow label={"Contact"} value={details?.phone || "N/A"} />
          <StudentDetailRow
            label={"Address"}
            value={details?.address || "N/A"}
          />
          <StudentDetailRow
            label={"Religion"}
            value={details?.religion || "N/A"}
          />
        </div>

        <div className="py-3 px-3">
          <h3 className="font-bold text-yankee_blue mb-3 text-lg">
            Guardian Information
          </h3>

          <StudentDetailRow
            label={"Name"}
            value={`Mr & Mrs ${guardian?.name || "N/A"}`}
          />
          <StudentDetailRow
            label={"Email address"}
            value={guardian?.email || "N/A"}
          />
          <StudentDetailRow
            label={"Contact"}
            value={guardian?.phone || "N/A"}
          />
          <StudentDetailRow
            label={"Address"}
            value={guardian?.address || "N/A"}
          />
          <StudentDetailRow
            label={"Occupation"}
            value={guardian?.occupation || "N/A"}
          />
        </div>

        <div className="py-3 px-3">
          <h3 className="font-bold text-yankee_blue mb-3 text-lg">
            Medical Information
          </h3>

          <div className="grid grid-cols-2">
            <StudentDetailRow
              label={"Weight"}
              value={details?.weight || "N/A"}
            />
            <StudentDetailRow
              label={"Height"}
              value={details?.height || "N/A"}
            />
          </div>

          <div className="grid grid-cols-2">
            <StudentDetailRow
              label={"Blood group"}
              value={details?.bloodType || "N/A"}
            />
            <StudentDetailRow
              label={"Genotype"}
              value={details?.genoType || "N/A"}
            />
          </div>
        </div>

        {/* buttons */}
        <div className="py-3 px-3 mt-auto">
          <button
            className="bg-transparent text-coral_red h-10 w-full rounded-md text-sm font-semibold"
            onClick={closeSideModal}
          >
            Back to list
          </button>
        </div>
      </div>
    </div>
  );
};
export default StudentDetails;
