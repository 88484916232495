import { ClipLoader } from "react-spinners";

import Logo from "../../../shared/components/Logo";
import useVerifyPasswordResetToken from "../../../shared/api/auth/recovery/useVerifyPasswordResetToken";

const VerifyPasswordReset = () => {
  const { loading } = useVerifyPasswordResetToken();

  return (
    <div className="">
      <div className="bg-white py-4 px-[5%]">
        <Logo />
      </div>

      <div className="grid place-items-center h-[90%]">
        <div>
          <div className="min-h-[60px] flex justify-center items-center">
            <ClipLoader loading={loading} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default VerifyPasswordReset;
