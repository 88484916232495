import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import client from "../../../shared/api/client";
import { handleToast } from "../../../shared/utils/functions";
import handleApiError from "../../../shared/hooks/handleApiError";
import FormInput from "../../../shared/components/form/FormInput";
import FormButton from "../../../shared/components/form/FormButton";
import SelectInput from "../../../shared/components/form/SelectInput";

const EducationProfile = ({ user, fetchUser, handleCancel }) => {
  const { teacherId } = useParams();

  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({});

  useEffect(() => {
    setDetails({
      primarySchoolName: user?.primarySchoolName || "",
      primarySchoolEndYear: Number(user?.primarySchoolEndYear) || 0,
      primarySchoolStartYear: Number(user?.primarySchoolStartYear) || 0,
      secondarySchoolName: user?.secondarySchoolName || "",
      secondarySchoolEndYear: Number(user?.secondarySchoolEndYear) || 0,
      secondarySchoolStartYear: Number(user?.secondarySchoolStartYear) || 0,
      secondarySchoolDepartment: user?.secondarySchoolDepartment || "",
      tertiarySchoolName: user?.tertiarySchoolName || "",
      tertiarySchoolEndYear: Number(user?.tertiarySchoolEndYear) || 0,
      tertiarySchoolStartYear: Number(user?.tertiarySchoolStartYear) || 0,
      tertiarySchoolCourse: user?.tertiarySchoolCourse || "",
      tertiarySchoolFaculty: user?.tertiarySchoolFaculty || "",
    });
  }, [user]);

  async function submit() {
    setLoading(true);

    try {
      const { data } = await client.patch(
        `/user/${teacherId}/education`,
        details
      );

      fetchUser();

      handleToast(data?.message);
      setLoading(false);
      // navigate(`/${teacherId}/profile/education`);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }

  return (
    <div>
      <h3 className="side_page_title">Edit education profile</h3>

      <h3 className="text-black-text font-semibold my-2">Primary school</h3>
      <div className="mb-10 grid gap-4 md:grid-cols-2 lg:grid-cols-3">
        <FormInput
          label={"School name"}
          placeholder={"Enter school name"}
          value={details?.primarySchoolName || ""}
          onChange={(e) =>
            setDetails({ ...details, primarySchoolName: e.currentTarget.value })
          }
        />

        <FormInput
          label={"Start year"}
          placeholder={"Enter start year"}
          value={details?.primarySchoolStartYear || ""}
          type="number"
          min="1900"
          max="2099"
          step="1"
          onChange={(e) =>
            setDetails({
              ...details,
              primarySchoolStartYear: e.currentTarget.value,
            })
          }
        />

        <FormInput
          label={"End year"}
          placeholder={"Enter end year"}
          value={details?.primarySchoolEndYear || ""}
          type="number"
          min="1900"
          max="2099"
          step="1"
          onChange={(e) =>
            setDetails({
              ...details,
              primarySchoolEndYear: e.currentTarget.value,
            })
          }
        />
      </div>

      <h3 className="text-black-text font-semibold my-2">Secondary school</h3>
      <div className="mb-10 grid gap-4 md:grid-cols-2 lg:grid-cols-3">
        <FormInput
          label={"School name"}
          placeholder={"Enter school name"}
          value={details?.secondarySchoolName || ""}
          onChange={(e) =>
            setDetails({
              ...details,
              secondarySchoolName: e.currentTarget.value,
            })
          }
        />

        <FormInput
          label={"Start year"}
          placeholder={"Enter start year"}
          value={details?.secondarySchoolStartYear || ""}
          type="number"
          min="1900"
          max="2099"
          step="1"
          onChange={(e) =>
            setDetails({
              ...details,
              secondarySchoolStartYear: e.currentTarget.value,
            })
          }
        />

        <FormInput
          label={"End year"}
          placeholder={"Enter end year"}
          value={details?.secondarySchoolEndYear || ""}
          type="number"
          min="1900"
          max="2099"
          step="1"
          onChange={(e) =>
            setDetails({
              ...details,
              secondarySchoolEndYear: e.currentTarget.value,
            })
          }
        />

        <SelectInput
          label={"Department"}
          placeholder={
            details?.secondarySchoolDepartment || "Choose Department"
          }
          required
          handleSelect={(e) =>
            setDetails({
              ...details,
              secondarySchoolDepartment: e.currentTarget.value,
            })
          }
          options={[
            { name: "Science Department", value: "science" },
            { name: "Art Department", value: "art" },
            { name: "Commercial Department", value: "commercial" },
          ]}
        />
      </div>

      <h3 className="text-black-text font-semibold my-2">Higher institution</h3>
      <div className="mb-10 grid gap-4 md:grid-cols-2 lg:grid-cols-3">
        <FormInput
          label={"School name"}
          placeholder={"Enter school name"}
          value={details?.tertiarySchoolName || ""}
          onChange={(e) =>
            setDetails({
              ...details,
              tertiarySchoolName: e.currentTarget.value,
            })
          }
        />

        <FormInput
          label={"Course"}
          placeholder={"Enter your course"}
          value={details?.tertiarySchoolCourse || ""}
          onChange={(e) =>
            setDetails({
              ...details,
              tertiarySchoolCourse: e.currentTarget.value,
            })
          }
        />

        <FormInput
          label={"Faculty"}
          placeholder={"Enter your faculty"}
          value={details?.tertiarySchoolFaculty || ""}
          onChange={(e) =>
            setDetails({
              ...details,
              tertiarySchoolFaculty: e.currentTarget.value,
            })
          }
        />

        <FormInput
          label={"Start year"}
          placeholder={"Enter start year"}
          value={details?.tertiarySchoolStartYear || ""}
          type="number"
          min="1900"
          max="2099"
          step="1"
          onChange={(e) =>
            setDetails({
              ...details,
              tertiarySchoolStartYear: e.currentTarget.value,
            })
          }
        />

        <FormInput
          label={"End year"}
          placeholder={"Enter end year"}
          value={details?.tertiarySchoolEndYear || ""}
          type="number"
          min="1900"
          max="2099"
          step="1"
          onChange={(e) =>
            setDetails({
              ...details,
              tertiarySchoolEndYear: e.currentTarget.value,
            })
          }
        />
      </div>

      <div className="max-w-xs mx-auto my-10">
        <FormButton title={"Update"} loading={loading} onClick={submit} />
      </div>
    </div>
  );
};

export default EducationProfile;
