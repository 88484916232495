import React, { useState } from "react";

export default function PageActions({ actions }) {
  const [showOptions, setShowOptions] = useState(false);

  return (
    <div className="relative">
      <button
        className="h-8 xxs:h-8 ssm:h-8 rounded-sm px-3 bg-yankee_blue/10 text-yankee_blue font-semibold"
        onClick={() => setShowOptions(!showOptions)}
      >
        <small className="font-bold">Action</small>
      </button>

      {showOptions ? (
        <div className="absolute right-0 top-9 xxs:top-9 ssm:top-10 w-44 bg-white shadow-lg">
          {actions?.map((action, key) => (
            <button
              key={key}
              className="rounded-sm text-yankee_blue py-2 w-full text-right px-3 hover:bg-primary_light_20"
              onClick={() => {
                setShowOptions(false);
                action?.onClick();
              }}
            >
              <small className="block">{action?.label}</small>
            </button>
          ))}
        </div>
      ) : null}
    </div>
  );
}
