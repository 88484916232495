import React from "react";

export default function DataPlaceholder({ value, label, className }) {
  return (
    <div className={`${className}`}>
      <p className="text-sm text-grey">{label}</p>

      <h4 className="text-yankee_blue font-bold text-sm capitalize">
        {value || "N/A"}
      </h4>
    </div>
  );
}
