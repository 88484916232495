import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import client from "../client";
import handleApiError from "../../hooks/handleApiError";

export default function useFetchActiveTeacherJobs() {
  const { teacherId } = useParams();
  const [loading, setLoading] = useState(false);
  const [classRoles, setClassRoles] = useState([]);
  const [subjectRoles, setSubjectRoles] = useState([]);

  const request = useCallback(async () => {
    setLoading(true);

    try {
      const res = await client.get(`/job/teacher/${teacherId}/roles/all`);

      setClassRoles(
        res.data?.data?.filter((jr) => jr?.roleType?.toLowerCase() === "class")
      );
      setSubjectRoles(
        res.data?.data?.filter(
          (jr) => jr?.roleType?.toLowerCase() === "subject"
        )
      );

      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, [teacherId]);

  useEffect(() => {
    request();
  }, [request]);

  return { classRoles, subjectRoles, loading, request };
}
