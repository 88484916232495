import { useCallback, useEffect, useState } from "react";
import client from "../../client";
import handleApiError from "../../../hooks/handleApiError";

const useFetchClassSubjects = (classId) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const request = useCallback(async () => {
    setLoading(true);

    try {
      const res = await client.get(`/classes/${classId}/subjects`);

      setData(res.data?.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, [classId]);

  useEffect(() => {
    if (classId) request();
  }, [request, classId]);

  return { data, loading, request };
};
export default useFetchClassSubjects;
