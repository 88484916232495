import { PiBooks } from "react-icons/pi";
import { FiUsers } from "react-icons/fi";
import { BiLogOut } from "react-icons/bi";
import { useEffect, useState } from "react";
import { GrScorecard } from "react-icons/gr";
import { GiBookshelf } from "react-icons/gi";
import { MdOutlineCoPresent } from "react-icons/md";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { Sidebar as ProSidebar, Menu, MenuItem } from "react-pro-sidebar";

import Logo from "../Logo";
import { useSelector } from "react-redux";
import { selectCalendar } from "../../../redux/slices/school/selectors";

const ClassSidebar = ({ toggleMenu }) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const session = query.get("session");
  const term = query.get("term");
  const { teacherId, classId, schoolId } = useParams();

  const [activeTab, setActiveTab] = useState("");
  console.log(activeTab, "active tab");
  const currentCalendar = useSelector(selectCalendar);

  useEffect(() => {
    setActiveTab(location.pathname?.split("/"));
  }, [location, setActiveTab]);

  return (
    <ProSidebar
      className="h-full bg-white border-none"
      backgroundColor="white"
      width="230px"
    >
      <div className="px-8 py-5 mb-5 border-b ">
        <Logo />
      </div>

      <Menu
        menuItemStyles={{
          button: {
            [`&`]: {
              height: "40px",
            },
          },
        }}
        stye
      >
        <MenuItem
          icon={<FiUsers />}
          component={
            <NavLink
              to={`/${teacherId}/workspace/${schoolId}/class/${classId}/students`}
            />
          }
          className={`${
            activeTab?.[6] === "students"
              ? "sidebar_active"
              : "sidebar_inactive"
          }`}
          onClick={toggleMenu}
        >
          Students
        </MenuItem>

        <MenuItem
          icon={<PiBooks />}
          component={
            <NavLink
              to={`/${teacherId}/workspace/${schoolId}/class/${classId}/subjects`}
            />
          }
          className={`${
            activeTab?.[6] === "subjects"
              ? "sidebar_active"
              : "sidebar_inactive"
          }`}
          onClick={toggleMenu}
        >
          Subjects
        </MenuItem>

        <MenuItem
          icon={<MdOutlineCoPresent />}
          component={
            <NavLink
              to={`/${teacherId}/workspace/${schoolId}/class/${classId}/attendance`}
            />
          }
          className={`${
            activeTab?.[6] === "attendance"
              ? "sidebar_active"
              : "sidebar_inactive"
          }`}
          onClick={toggleMenu}
        >
          Attendance
        </MenuItem>

        <MenuItem
          icon={<GrScorecard />}
          component={
            <NavLink
              to={`/${teacherId}/workspace/${schoolId}/class/${classId}/scorecards`}
            />
          }
          className={`${
            activeTab?.[6] === "scorecards"
              ? "sidebar_active"
              : "sidebar_inactive"
          }`}
          onClick={toggleMenu}
        >
          Scorecards
        </MenuItem>

        <MenuItem
          icon={<GiBookshelf />}
          component={
            <NavLink
              to={`/${teacherId}/workspace/${schoolId}/class/${classId}/reportcard?session=${
                session || currentCalendar?.session
              }&term=${term || currentCalendar?.term}`}
            />
          }
          className={`${
            activeTab?.[6] === "reportcard" || activeTab?.[8] === "reportcard"
              ? "sidebar_active"
              : "sidebar_inactive"
          }`}
          onClick={toggleMenu}
        >
          Report Cards
        </MenuItem>

        <MenuItem
          icon={<BiLogOut size={16} />}
          className="sidebar_inactive !text-coral_red"
          component={<NavLink to={`/${teacherId}/workspace`} />}
        >
          Exit Class
        </MenuItem>
      </Menu>
    </ProSidebar>
  );
};

export default ClassSidebar;
