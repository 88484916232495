import { useCallback, useState } from "react";
import client from "../client";
import handleApiError from "../../hooks/handleApiError";

export default function useDeleteScorecardById() {
  const [loading, setLoading] = useState(false);

  const request = useCallback(async (scorecardId) => {
    try {
      setLoading(true);

      await client.delete(`/result/reportcard/scorecard/${scorecardId}/delete`);

      setLoading(false);
      return true;
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, []);

  return { request, loading };
}
