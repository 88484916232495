import { ClipLoader } from "react-spinners";

import Logo from "../../../shared/components/Logo";
import authImage from "../../../assets/images/login.png";
import useVerifyEmail from "../../../shared/api/auth/useVerifyEmail";

const VerifyEmail = () => {
  const { loading } = useVerifyEmail();

  return (
    <div className="auth_page">
      <div className="auth_page_content_grid">
        <div className="auth_logo_wrapper">
          <Logo />
        </div>

        <div className="auth_page_content">
          <h2 className={"auth_page_title"}>
            Hello, We're verifying your email
          </h2>

          <div className="grid place-items-center mt-10 h-[50%]">
            <div>
              <div className="min-h-[60px] flex justify-center items-center">
                <ClipLoader loading={loading} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="auth_page_image_grid">
        <div className="auth_image_container">
          <img
            src={authImage}
            alt="GMF"
            className="block w-full h-full object-cover"
          />
        </div>
      </div>
    </div>
  );
};
export default VerifyEmail;
