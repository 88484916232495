import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { selectCalendar } from "../../../../redux/slices/school/selectors";

import Subjects from "./components/Subjects";
import ScorecardTable from "./components/ScorecardTable";
import ScorecardOverview from "./components/ScorecardOverview";
import SideModal from "../../../../shared/components/SideModal";
import useFetchClassDetails from "../../../../shared/api/hooks/useFetchClassDetails";
import useFetchScorecardsByClassSubjectId from "../../../../shared/api/cards/useFetchScorecardsByClassSubjectId";
import useFetchClassSubjects from "../../../../shared/api/class/subjects/useFetchClassSubjects";

const Scorecards = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const action = query.get("action");
  const navigate = useNavigate();

  const { classId, teacherId, schoolId } = useParams();
  const calendar = useSelector(selectCalendar);

  const { loading: classLoading, data: classDetails } =
    useFetchClassDetails(classId);
  const { loading: subjectLoading, data: subjects } =
    useFetchClassSubjects(classId);

  const [selectedSubject, setSelectedSubject] = useState(null);
  const { data: scorecards, loading } = useFetchScorecardsByClassSubjectId(
    selectedSubject?.classSubjectId,
    calendar
  );

  function handleSelectSubject(params) {
    setSelectedSubject(params);
    closeSideModal();
  }

  function handleShowSubjectModal() {
    navigate(
      `/${teacherId}/workspace/${schoolId}/class/${classId}/scorecards?action=show-subjects`
    );
  }

  function closeSideModal() {
    navigate(`/${teacherId}/workspace/${schoolId}/class/${classId}/scorecards`);
  }

  return (
    <div className={action ? "page" : "full_page"}>
      <div className="mb-5 flex items-center justify-between">
        <div className="page_title_container">
          <h1 className="page_title">Scorecards</h1>

          <p className="page_subtitle">
            {classDetails
              ? `(${classDetails?.category} ${classDetails?.suffix})`
              : null}
          </p>
        </div>

        <button
          className="flex items-center justify-center gap-1 bg-primary h-8 xxs:h-8 ssm:h-9 rounded-sm text-white px-3 min-w-[80px]"
          onClick={handleShowSubjectModal}
        >
          <small>Select Subject</small>
        </button>
      </div>

      <div className="main_content">
        <ScorecardOverview
          classDetails={classDetails}
          classLoading={classLoading}
          selectedSubject={selectedSubject}
          scorecardLength={scorecards?.length}
          subjectLength={subjects?.length}
        />

        <ScorecardTable data={scorecards} loading={loading} />
      </div>

      <SideModal isVisible={action} closeSideModal={closeSideModal}>
        {/* <EditScorecard
          scorecardId={scorecardId}
          fetchSubjectScorecard={fetchSubjectScorecard}
          handleCancelEditScorecard={closeSideModal}
        /> */}

        <Subjects
          closeSideModal={closeSideModal}
          action={action}
          subjects={subjects}
          handleSelectSubject={handleSelectSubject}
          selectedSubject={selectedSubject}
          subjectLoading={subjectLoading}
        />
      </SideModal>
    </div>
    // <div>
    //   <div className="page">
    //     <h1 className="page_title mb-5">
    //       Scorecards{" "}
    //       {classDetails
    //         ? `(${classDetails?.category} ${classDetails?.suffix})`
    //         : null}
    //     </h1>

    //     <ClassNavbar activeIndex={4} />

    //     <div className="main_content">
    //       <ScorecardOverview
    //         classDetails={classDetails}
    //         classLoading={classLoading}
    //         selectedSubject={selectedSubject}
    //         scorecardLength={scorecards?.length}
    //         subjectLength={subjects?.length}
    //         handleShowSubjectModal={handleShowSubjectModal}
    //       />

    //       <ScorecardTable data={scorecards} loading={loading} />
    //     </div>
    //   </div>

    //   {action ? (
    //     <div className="page_dark_overlay" onClick={closeSideModal} />
    //   ) : null}

    //   <div className={`page_right_bar ${action ? "block" : ""}`}>
    //     <Subjects
    //       subjects={subjects}
    //       handleSelectSubject={handleSelectSubject}
    //       selectedSubject={selectedSubject}
    //       subjectLoading={subjectLoading}
    //       action={action}
    //     />
    //   </div>
    // </div>
  );
};
export default Scorecards;
