import React from "react";
import DataPlaceholder from "../general/DataPlaceholder";

export default function MedicalDetails({ details }) {
  // console.log(detail);
  return (
    <div className="bg-white px-3 py-5 rounded-sm">
      <div className="flex items-center justify-between mb-3">
        <h4>Medical Information</h4>
      </div>

      {/**
      |--------------------------------------------------
      | Details
      |--------------------------------------------------
      */}
      <div className="grid grid-cols-3 gap-y-4 gap-x-3">
        <DataPlaceholder label={"Weight"} value={details?.weight || "N/A"} />
        <DataPlaceholder label={"Height"} value={details?.height || "N/A"} />

        <DataPlaceholder
          label={"Blood group"}
          value={details?.bloodType || "N/A"}
        />
        <DataPlaceholder
          label={"Genotype"}
          value={details?.genoType || "N/A"}
        />
        <DataPlaceholder label={"Others"} value={details?.others || "N/A"} />
      </div>
    </div>
  );
}
