import React from "react";
import DataPlaceholder from "../general/DataPlaceholder";

export default function GuardianDetails({ guardian }) {
  // console.log(detail);
  return (
    <div className="bg-white px-3 py-5 rounded-sm">
      <div className="flex items-center justify-between mb-3">
        <h4>Guardian Information</h4>
      </div>

      {/**
      |--------------------------------------------------
      | Details
      |--------------------------------------------------
      */}
      <div className="grid grid-cols-3 gap-y-4 gap-x-3">
        <DataPlaceholder label={"Name"} value={`${guardian?.name || "N/A"}`} />
        <DataPlaceholder
          label={"Email address"}
          value={guardian?.email || "N/A"}
        />

        <DataPlaceholder label={"Contact"} value={guardian?.phone || "N/A"} />
        <DataPlaceholder label={"Address"} value={guardian?.address || "N/A"} />
        <DataPlaceholder
          label={"Occupation"}
          value={guardian?.occupation || "N/A"}
        />
        <DataPlaceholder
          value={guardian?.nationality || "N/A"}
          label={"Nationality"}
        />
        <DataPlaceholder
          value={guardian?.state || "N/A"}
          label={"State of origin"}
        />
        <DataPlaceholder value={guardian?.lga || "N/A"} label={"L.G.A"} />
      </div>
    </div>
  );
}
