import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import client from "../../../shared/api/client";
import handleApiError from "../../../shared/hooks/handleApiError";
import SelectInput from "../../../shared/components/form/SelectInput";
import FormInput from "../../../shared/components/form/FormInput";
import FormButton from "../../../shared/components/form/FormButton";
import { handleToast } from "../../../shared/utils/functions";

const MedicalProfile = ({ user, fetchUser, handleCancel }) => {
  const { teacherId } = useParams();

  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setDetails({
      height: user?.height || "",
      weight: user?.weight || "",
      others: user?.others || "",
      genoType: user?.genoType || "",
      bloodType: user?.bloodType || "",
    });
  }, [user]);

  async function submit() {
    setLoading(true);

    try {
      const { data } = await client.patch(
        `/user/${teacherId}/medical`,
        details
      );

      fetchUser();

      handleToast(data?.message);
      setLoading(false);
      // navigate(`/${teacherId}/profile/medical`);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }

  return (
    <div>
      <h3 className="side_page_title">Edit medical profile</h3>

      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
        <FormInput
          label={"Height"}
          placeholder={"Enter your height in cm"}
          value={details?.height || ""}
          onChange={(e) =>
            setDetails({ ...details, height: e.currentTarget.value })
          }
        />

        <FormInput
          label={"Weight"}
          placeholder={"Enter your weight in kg"}
          value={details?.weight || ""}
          onChange={(e) =>
            setDetails({ ...details, weight: e.currentTarget.value })
          }
        />

        <SelectInput
          label={"Blood group"}
          placeholder={details?.bloodType || "Choose your blood group"}
          required
          handleSelect={(e) =>
            setDetails({ ...details, bloodType: e.currentTarget.value })
          }
          options={[
            { name: "A+", value: "A+" },
            { name: "A-", value: "A-" },
            { name: "B+", value: "B+" },
            { name: "B-", value: "B-" },
            { name: "AB+", value: "AB+" },
            { name: "AB-", value: "AB-" },
            { name: "O+", value: "O+" },
            { name: "O-", value: "O-" },
          ]}
        />

        <SelectInput
          label={"Genotype"}
          placeholder={details?.genoType || "Choose your genotype"}
          required
          handleSelect={(e) =>
            setDetails({ ...details, genoType: e.currentTarget.value })
          }
          options={[
            { name: "AA", value: "AA" },
            { name: "AS", value: "AS" },
            { name: "SS", value: "SS" },
            { name: "AC", value: "AC" },
            { name: "SC", value: "SC" },
          ]}
        />

        <FormInput
          label={"Other"}
          value={details?.others || "Enter any form of disability"}
          onChange={(e) =>
            setDetails({ ...details, others: e.currentTarget.value })
          }
        />
      </div>

      <div className="max-w-xs mx-auto my-10">
        <FormButton title={"Update"} loading={loading} onClick={submit} />
      </div>
    </div>
  );
};

export default MedicalProfile;
