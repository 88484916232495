import React from "react";
import { IoClose } from "react-icons/io5";

export default function SideModal({
  float,
  isVisible,
  closeSideModal,
  useOverlay = true,
  children,
}) {
  if (!isVisible) return null;

  return (
    <div className={`fixed top-0 bottom-0 right-0 left-0 lg:left-[230px]`}>
      <div className="w-full h-full relative">
        <div
          className={`absolute top-0 left-0 bottom-0 right-[80%] bg-black/20 xs:right-[300px] ${
            useOverlay ? "!block" : "!hidden"
          } `}
        />

        {useOverlay ? (
          <button
            className="absolute top-3 right-[82%] h-10 w-10 bg-white flex items-center justify-center rounded-full xs:right-[310px]"
            onClick={closeSideModal}
          >
            <IoClose className="text-coral_red text-xl" />
          </button>
        ) : null}

        <div
          className={`overflow-auto py-5 px-3 absolute top-0 bottom-0 right-0 bg-white  max-w-[300px] w-[80%] xs:min-w-[300px] ${
            float ? "shadow-lg" : ""
          }`}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
