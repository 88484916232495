import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setUserDetails } from "../../../redux/slices/auth/slice";

import handleApiError from "../../hooks/handleApiError";
import client from "../client";

export default function useLogin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const request = useCallback(
    async (credential) => {
      try {
        setLoading(true);
        const { data } = await client.post("/auth/login", credential);

        localStorage.setItem("token", data?.data?.token);
        dispatch(setUserDetails(data?.data?.user));
        setLoading(false);

        navigate(`/${data?.data?.user?.userId}/dashboard`);
      } catch (error) {
        setLoading(false);
        handleApiError(error);
      }
    },
    [navigate, dispatch]
  );

  return { request, loading };
}
