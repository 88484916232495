import { FiUsers } from "react-icons/fi";
import TableOverviewCard from "../../../../../shared/components/TableOverviewCard";

const ScorecardsByTermTableOverview = ({
  obtainableScore,
  obtainedScore,
  average,
  reportCard,
}) => {
  return (
    <div className="table_wrapper">
      <div className="table_header !min-w-[850px]">
        <div className="pl-3">
          <h4 className="text-yankee_blue font-bold text-base capitalize">
            {`${reportCard?.category || ""} ${reportCard?.suffix || ""}`}
          </h4>
        </div>

        <TableOverviewCard
          icon={<FiUsers className="text-morning_blue" />}
          label={"Obtainable Score"}
          value={obtainableScore || 0}
          valueBackgroundColor={"bg-azureish_white"}
          valueColor={"text-primary"}
        />

        <TableOverviewCard
          icon={<FiUsers className="text-morning_blue" />}
          label={"Score Obtained"}
          value={obtainedScore || 0}
          valueBackgroundColor={"bg-banana_mania"}
          valueColor={"text-saffron"}
        />

        <TableOverviewCard
          icon={<FiUsers className="text-morning_blue" />}
          label={"Average"}
          value={average || 0}
          valueBackgroundColor={"bg-water"}
          valueColor={"text-secondary"}
        />
      </div>
    </div>
  );
};

export default ScorecardsByTermTableOverview;
