import client from "../client";
import { useDispatch } from "react-redux";
import { useCallback, useState } from "react";

import handleApiError from "../../hooks/handleApiError";
import { setUserDetails } from "../../../redux/slices/auth/slice";

export default function useFetchUserDetails() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const request = useCallback(
    async (userId) => {
      setLoading(true);
      dispatch(setUserDetails(null));

      try {
        const { data } = await client.get(`/user/${userId}`);
        if (data?.success) dispatch(setUserDetails(data?.data));
        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleApiError(error);
      }
    },
    [dispatch]
  );

  return { request, loading };
}
