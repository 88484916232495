import { useParams } from "react-router-dom";

import SubjectTable from "./components/SubjectTable";
import SubjectOverview from "./components/SubjectOverview";
import useFetchClassDetails from "../../../../shared/api/hooks/useFetchClassDetails";
import useFetchClassSubjects from "../../../../shared/api/class/subjects/useFetchClassSubjects";

const Subjects = () => {
  const { classId } = useParams();
  const { loading: classLoading, data: classDetails } =
    useFetchClassDetails(classId);
  const { loading: subjectLoading, data: subjects } =
    useFetchClassSubjects(classId);

  return (
    <div>
      <div className="full_page">
        <h1 className="page_title mb-5">
          Subjects{" "}
          {classDetails
            ? `(${classDetails?.category} ${classDetails?.suffix})`
            : null}
        </h1>

        {/* <ClassNavbar activeIndex={2} /> */}

        <div className="main_content">
          <SubjectOverview
            classDetails={classDetails}
            classLoading={classLoading}
          />

          <SubjectTable loading={subjectLoading} data={subjects} />
        </div>
      </div>

      {/* <div className="page_right_bar"></div> */}
    </div>
  );
};

export default Subjects;
