import { useCallback, useEffect, useState } from "react";
import client from "../client";
import handleApiError from "../../hooks/handleApiError";

export default function useFetchCalendars(state) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const request = useCallback(async (_state) => {
    try {
      setLoading(true);

      const res = await client.get(`/calendar/state/${_state}`);

      setData(res.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, []);

  useEffect(() => {
    if (state) request(state);
  }, [request, state]);

  return { data, loading, request };
}
