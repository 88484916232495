import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import {
  selectCalendar,
  selectSchoolDetails,
} from "../../../redux/slices/school/selectors";
import client from "../client";

import handleApiError from "../../hooks/handleApiError";
import { setCalendar } from "../../../redux/slices/school/slice";

export default function useFetchActiveCalendar() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const calendar = useSelector(selectCalendar);
  const school = useSelector(selectSchoolDetails);

  const request = useCallback(
    async (_state) => {
      try {
        setLoading(true);

        const res = await client.get(`/calendar/state/${_state}/active`);

        dispatch(setCalendar(res.data?.data));
        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleApiError(error);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (!calendar && school) request(school?.state);
  }, [calendar, request, school]);

  return { loading, request };
}
