import React from "react";

export default function SelectInput({
  name,
  label,
  style,
  options,
  leftIcon,
  inputStyle,
  placeholder,
  selectColor,
  getKeyLabel,
  handleSelect,
  className = "mb-5",
  optionKeyLabel = "name",
  optionValueLabel = "value",
  containerStyle = "border border-greyX11",
  ...rest
}) {
  return (
    <div className={`${className}`}>
      {label ? (
        <label
          className="text-yankee_blue font-semibold text-sm"
          htmlFor={name}
        >
          {label}
        </label>
      ) : null}

      <div
        className={`rounded-sm overflow-hidden flex items-center px-1 h-9 xs:h-10 ssm:h-11 ${
          label && "mt-1"
        } ${containerStyle}`}
      >
        <select
          id={name}
          style={{ color: selectColor }}
          onChange={handleSelect}
          className={`md:text-sm text-xs py-0 px-1 border-0 !outline-0 bg-transparent flex-1 h-full text-light_slate_grey ${inputStyle}`}
          placeholder={placeholder || label || ""}
          {...rest}
        >
          <option value="">{placeholder}</option>

          {options?.map((opt, i) => (
            <option key={i} value={opt?.[optionValueLabel]}>
              {getKeyLabel?.(opt) || opt?.[optionKeyLabel]}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
