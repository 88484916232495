import { useCallback, useState } from "react";
import client from "../client";
import handleApiError from "../../hooks/handleApiError";

export default function useUpdateNotificationStatus() {
  const [loading, setLoading] = useState(false);

  const request = useCallback(async (id) => {
    try {
      setLoading(true);
      await client.put("/notification/" + id);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, []);

  return { request, loading };
}
