import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import handleApiError from "../../hooks/handleApiError";
import client from "../client";
import { handleToast } from "../../utils/functions";

export default function useVerifyEmail() {
  const { userId, token } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const request = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await client.post(
        `/auth/verify/email/${userId}/${token}`
      );

      setLoading(false);
      if (data?.success) {
        handleToast(data?.message);
        navigate("/login", { replace: true });
      }
    } catch (error) {
      setLoading(false);
      handleApiError(error);
      navigate("/login", { replace: true });
    }
  }, [navigate, token, userId]);

  useEffect(() => {
    request();
  }, [request]);

  return { loading, request };
}
