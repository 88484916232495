import { useParams } from "react-router-dom";

import ProfileOverview from "./components/ProfileOverview";
import UpcominEventOverview from "./components/UpcominEventOverview";
import upcomingEventImage from "../../assets/images/event-upcoming.png";
import RolesV2 from "./components/RolesV2";
import useFetchActiveTeacherJobs from "../../shared/api/dashboard/useFetchActiveTeacherJobs";

const Dashboard = () => {
  const { teacherId } = useParams();

  const { classRoles, subjectRoles } = useFetchActiveTeacherJobs(teacherId);

  return (
    <div className="z-0">
      <div className="page">
        <h1 className="page_title mb-10">Overview</h1>

        <div className="grid gap-5 mb-10 sm:grid-cols-2 z-10">
          <RolesV2
            label={"Class Roles"}
            total={classRoles?.length}
            inactive={classRoles?.filter((jr) => !jr?.isCurrent)?.length}
            active={classRoles?.filter((jr) => jr?.isCurrent)?.length}
          />

          <RolesV2
            label={"Subject Roles"}
            total={subjectRoles?.length}
            inactive={subjectRoles?.filter((jr) => !jr?.isCurrent)?.length}
            active={subjectRoles?.filter((jr) => jr?.isCurrent)?.length}
          />
        </div>

        {/* <div className="mb-10">
          <JobChart />
        </div> */}

        <div className="grid gap-5 sm:grid-cols-2">
          <ProfileOverview />

          <UpcominEventOverview />
        </div>
      </div>

      <div className="page_right_bar">
        <h1 className="side_page_title">Upcoming Events</h1>

        <div className="flex items-center justify-center mt-20">
          <div className="w-[80%]">
            <img
              src={upcomingEventImage}
              alt=""
              className="h-full w-full object-contain"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
