import { FiUsers } from "react-icons/fi";

import { getTotalStudents } from "../../../../../shared/utils/functions";

import Loader from "../../../../../shared/components/Loader";
import StudentOverviewCard from "../../components/StudentOverviewCard";

const ReportCardOverview = ({ classDetails, classLoading, subjectLength }) => {
  return (
    <>
      {classLoading ? (
        <Loader loading={classLoading} className={"min-h-[20px]"} size={20} />
      ) : null}

      <div className="table_wrapper">
        <div className="table_header !min-w-[800px]">
          <div className="pl-3"></div>

          <StudentOverviewCard
            icon={<FiUsers className="text-morning_blue" />}
            label={"Total Students"}
            value={getTotalStudents(classDetails) || "-"}
            valueBackgroundColor={"bg-azureish_white"}
            valueColor={"text-primary"}
          />

          <StudentOverviewCard
            icon={<FiUsers className="text-morning_blue" />}
            label={"Total Scorecards"}
            value={"-"}
            valueBackgroundColor={"bg-water"}
            valueColor={"text-secondary"}
          />

          <StudentOverviewCard
            icon={<FiUsers className="text-morning_blue" />}
            label={"Total Subjects"}
            value={subjectLength || "-"}
            valueBackgroundColor={"bg-banana_mania"}
            valueColor={"text-saffron"}
          />
        </div>
      </div>
    </>
  );
};
export default ReportCardOverview;
