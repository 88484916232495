import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";

import { selectCalendar } from "../../../../redux/slices/school/selectors";

import client from "../../../../shared/api/client";
import ScorecardTable from "./components/ScorecardTable";
import ScorecardDetails from "./components/ScorecardDetails";
import { handleToast } from "../../../../shared/utils/functions";
import ClassSubjectOverview from "./components/ClassSubjectOverview";
import handleApiError from "../../../../shared/hooks/handleApiError";
import StudentDetails from "../../class/Students/components/StudentDetails";
import useFetchClassDetails from "../../../../shared/api/hooks/useFetchClassDetails";

const ClassSubject = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const studentId = query.get("selected");

  const navigate = useNavigate();
  const calendar = useSelector(selectCalendar);
  const { classSubjectId, classId, schoolId, teacherId } = useParams();

  const { loading: classLoading, data: classDetails } =
    useFetchClassDetails(classId);
  const [classSubject, setClassSubject] = useState(null);

  const [loadingScorecards, setLoadingScorecards] = useState(false);
  const [scorecards, setScorecards] = useState(null);

  const [score, setScore] = useState({
    continuousAssessmentOneScore: 0,
    continuousAssessmentTwoScore: 0,
    examScore: 0,
  });
  const [selectedScorecard, setSelectedScorecard] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const fetchClassSubject = useCallback(async () => {
    const { data } = await client.get(
      `/classes/${classId}/subjects/${classSubjectId}`
    );
    setClassSubject(data?.data);
  }, [classId, classSubjectId]);

  useEffect(() => {
    fetchClassSubject();
  }, [fetchClassSubject]);

  const fetchSubjectScorecard = useCallback(async () => {
    setLoadingScorecards(true);

    try {
      const { data } = await client.get(
        `/result/${classSubjectId}/scorecard?session=${calendar?.session}&term=${calendar?.term}`
      );

      setScorecards(data?.data);

      setLoadingScorecards(false);
    } catch (error) {
      setLoadingScorecards(false);
      handleApiError(error);
    }
  }, [calendar, classSubjectId]);

  useEffect(() => {
    if (calendar) {
      fetchSubjectScorecard();
    }
  }, [fetchSubjectScorecard, calendar]);

  async function submitScore() {
    setSubmitting(true);

    try {
      const { data } = await client.patch(
        `/result/scorecard/${selectedScorecard.scorecardId}`,
        score
      );

      setSelectedScorecard(null);
      fetchSubjectScorecard();

      setScore({
        continuousAssessmentOneScore: 0,
        continuousAssessmentTwoScore: 0,
        examScore: 0,
      });
      setSubmitting(false);
      handleToast(data?.message);
    } catch (error) {
      setSubmitting(false);
      handleApiError(error);
    }
  }

  function handleSelectScorecard(params) {
    navigate(
      `/${teacherId}/workspace/${schoolId}/subject/${classSubjectId}/class/${classId}`
    );

    setSelectedScorecard(params);
    setScore({
      continuousAssessmentOneScore: params?.continuousAssessmentOneScore,
      continuousAssessmentTwoScore: params?.continuousAssessmentTwoScore,
      examScore: params?.examScore,
    });
  }

  function handleCancel() {
    setSelectedScorecard(null);

    setScore({
      continuousAssessmentOneScore: 0,
      continuousAssessmentTwoScore: 0,
      examScore: 0,
    });
  }

  function handleNavigate(params) {
    handleCancel();
    navigate(
      `/${teacherId}/workspace/${schoolId}/subject/${classSubjectId}/class/${classId}?selected=${params?.studentId}`
    );
  }

  function closeSideModal() {
    handleCancel();
    navigate(
      `/${teacherId}/workspace/${schoolId}/subject/${classSubjectId}/class/${classId}`
    );
  }

  return (
    <div>
      <div className="page">
        <h1 className="page_title mb-5">Class Subjects</h1>

        <div className="main_content_header">
          <NavLink to={`/${teacherId}/workspace`}>
            <button className="main_content_header_btn">Workspace</button>
          </NavLink>
        </div>

        <div className="main_content">
          <ClassSubjectOverview
            classDetails={classDetails}
            classLoading={classLoading}
            scorecardLength={scorecards?.length}
            subject={classSubject?.name}
          />

          <ScorecardTable
            data={scorecards}
            loading={loadingScorecards}
            handleSelectScorecard={handleSelectScorecard}
            handleNavigate={handleNavigate}
          />
        </div>
      </div>

      {studentId || selectedScorecard ? (
        <div className="page_dark_overlay" onClick={closeSideModal} />
      ) : null}

      <div
        className={`page_right_bar !p-0 ${
          studentId || selectedScorecard ? "block" : ""
        }`}
      >
        <ScorecardDetails
          selectedScorecard={selectedScorecard}
          score={score}
          loading={submitting}
          setScore={setScore}
          onSubmit={submitScore}
          handleCancel={handleCancel}
        />

        <StudentDetails studentId={studentId} closeSideModal={closeSideModal} />
      </div>
    </div>
  );
};
export default ClassSubject;
